<script lang="ts">
	import { getIconFontCssUrl } from '@item-ind/icons';
	import '../app.css';
	import { page } from '$app/stores';
	import { PUBLIC_CDN_URL } from '$env/static/public';
	import ShortlinkScanner from '$lib/components/ShortlinkScanner.svelte';
	import { config } from '$lib/app-config';
	import { parsePlainLocale } from '$lib/parse_locale';

	$: localization = parsePlainLocale($page.params.locale) || config.fallbackLocalization;
</script>

<svelte:head>
	<link
		rel="preload"
		href="{PUBLIC_CDN_URL}/shared/fonts/roboto-condensed-v24-latin-ext_latin-regular.woff2"
		as="font"
		type="font/woff2"
		crossorigin="anonymous"
	/>
	<link
		rel="preload"
		href="{PUBLIC_CDN_URL}/shared/fonts/roboto-condensed-v24-latin-ext_latin-300.woff2"
		as="font"
		type="font/woff2"
		crossorigin="anonymous"
	/>
	<link rel="preload" href={getIconFontCssUrl(PUBLIC_CDN_URL)} as="style" />
	<link rel="stylesheet" href={getIconFontCssUrl(PUBLIC_CDN_URL)} />
</svelte:head>
<main>
	<slot />
	<ShortlinkScanner basepath={'/' + localization?.locale + '/profile-machinings'} />
</main>
