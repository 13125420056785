import { error } from '@sveltejs/kit';
import { loadDictionary } from '@item-ind/app-text-localization';
import type { LayoutLoad } from './$types';
import { assets } from '$app/paths';
import { dev, version } from '$app/environment';
import { config } from '$lib/app-config';
import { parsePlainLocale } from '$lib/parse_locale';

export const load = (async ({ fetch, params }) => {
	const localization = parsePlainLocale(params?.locale) || config.fallbackLocalization;
	if (!localization) {
		error(400);
	}

	const requests: [Promise<void>] = [
		loadDictionary({
			fetch,
			dictionaryPath: `${assets}/dictionary${dev || version.length !== 7 ? '' : `/${version}`}`,
			translationLanguage: localization?.lang
		})
	];

	await Promise.allSettled(requests);

	return {
		localization
	};
}) satisfies LayoutLoad;
