//This services uses the referrer service as described on
//https://item24.atlassian.net/wiki/spaces/SA/pages/1456767095/referrer
import { PUBLIC_REFERRER_URL } from '$env/static/public';

export interface resolvedData {
	projectId: string;
	lotId: string;
}

export async function resolveToProjectIdOrCNumber(shortlink: string): Promise<resolvedData> {
	const postData = { qrCodeContent: shortlink };
	const dummyResult: resolvedData = {
		projectId: '',
		lotId: ''
	};

	try {
		const response = await fetch(PUBLIC_REFERRER_URL + '/qr-metadata', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(postData)
		});

		if (response?.ok) {
			const data = await response.json();

			if (data?.cnumber) {
				return {
					projectId: data.cnumber,
					lotId: data.lot
				};
			} else if (data?.projectId) {
				return {
					projectId: data.projectId,
					lotId: data.lot
				};
			}
		}

		return dummyResult;
	} catch (error) {
		return dummyResult;
	}
}
