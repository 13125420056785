<script lang="ts">
	import { goto } from '$app/navigation';
	import { PUBLIC_APP_ID } from '$env/static/public';
	import { page } from '$app/stores';
	import { onMount } from 'svelte';
	import { useTranslation } from '@item-ind/app-text-localization';
	import { config } from '$lib/app-config';
	import { parsePlainLocale } from '$lib/parse_locale';
	import { resolveToProjectIdOrCNumber } from '$lib/services/shortlinkconverter';
	import { keyboardUtility } from '$lib/services/keyboardUtility';
	import NotificationPopup from './NotificationPopup.svelte';

	let notificationPopup: NotificationPopup;

	const keyboardUtl = new keyboardUtility();

	$: localization = parsePlainLocale($page.params.locale) || config.fallbackLocalization;
	$: t = useTranslation(PUBLIC_APP_ID, localization?.lang);
	$: $page.route, hidePopup();

	function hidePopup() {
		if (notificationPopup) {
			notificationPopup.hideMessage();
		}
	}

	export let basepath: string;

	let inputString: string = '';

	onMount(() => {
		window.addEventListener('keydown', handleKeyDown);
	});

	async function handleKeyDown(event: KeyboardEvent) {
		if (keyboardUtl.isModifierKeyPressed(event)) {
			return;
		}

		if (keyboardUtl.isRapidKeyboardEvent(event)) {
			if (!keyboardUtl.isEnterPressed(event)) {
				inputString += event.key;
				return;
			}

			// Looks like some QR on machined project labels contain content that includes
			// leading/trailing spaces/tab/linefeed/carriage-return.
			// Therefore, we trim the scanned string to remove unwanted space-like characters.
			inputString = inputString.trim();
			console.info(`Discovered scan result: "${inputString}"`);
			const result = await resolveToProjectIdOrCNumber(inputString.trim());

			if (!result.projectId) {
				await goto(basepath);
				notificationPopup.showMessage(t('shortlinkScanner.error.invalid-qr-code'));
				return;
			}

			const target = basepath + '/' + result.projectId + '/' + result.lotId;
			goto(target);
		} else {
			inputString = event.key;
		}
	}
</script>

<NotificationPopup bind:this={notificationPopup} />
