export class keyboardUtility {
	private lastKeyPressTimeMs: number | undefined;

	isEnterPressed(event: KeyboardEvent): boolean {
		return event.code === 'Enter' || event.code === 'NumpadEnter';
	}

	isModifierKeyPressed(event: KeyboardEvent): boolean {
		const controlKeys = ['Alt', 'AltGraph', 'CapsLock', 'Control', 'Shift'];
		return controlKeys.includes(event.key);
	}

	isRapidKeyboardEvent(event: KeyboardEvent): boolean {
		const maxKeyStrokeIntervalMs: number = 150;
		const currentTimeMs: number = new Date().getTime();

		// key is being held down
		if (event.repeat) {
			return false;
		}

		const timeDiffMs: number = currentTimeMs - (this.lastKeyPressTimeMs || currentTimeMs);

		this.lastKeyPressTimeMs = currentTimeMs;

		const isRapid = timeDiffMs <= maxKeyStrokeIntervalMs;
		return isRapid;
	}
}
